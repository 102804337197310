<template>
  <div>
    <el-tooltip class="item" effect="dark" content="Ajouter boitier" placement="top"><el-button type="primary"
        :icon="PlusIcon" plain @click="dialogFormAdd = true">Ajouter</el-button></el-tooltip>
    <el-dialog title="Ajout d'un boitier" v-model="dialogFormAdd" append-to-body center>
      <el-form :model="form" :rules="rules">
        <el-form-item prop="uniqueId" label="N° de série " :label-width="formLabelWidth">
          <el-input v-model.trim="form.uniqueId" autocomplete="off"
            placeholder="Veuillez saisir le numéro de série "></el-input>
        </el-form-item>
        <el-form-item label="N° d'appel " :label-width="formLabelWidth">
          <el-input v-model.trim="form.phone" autocomplete="off"
            placeholder="Veuillez saisir le numéro d'appel "></el-input>
        </el-form-item>
        <el-form-item label="Marque " prop="make" :label-width="formLabelWidth" placeholder="Veuillez saisir la marque">
          <el-select v-model="form.make" placeholder="Sélectionnez une marque" style="width: 100%">
            <el-option label="Albatross" value="Albatross"></el-option>
            <el-option label="Teltonika" value="Teltonika"></el-option>
            <el-option label="Gt06" value="Gt06"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Modèle " prop="model" :label-width="formLabelWidth" placeholder="Veuillez saisir le modèle">
          <el-input v-model.trim="form.model" autocomplete="off" placeholder="Veuillez saisir le modèle "></el-input>
        </el-form-item>
        <el-form-item v-if="isAppAdmin" label="Revendeur " :label-width="formLabelWidth">
          <el-select v-model="form.retailerId" placeholder="Revendeur" style="width: 100%">
            <el-option v-for="(item, index) in retailerOptions" :key="index" :label="item.name" :value="item.id"
              clearable></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Organisation " :label-width="formLabelWidth">
          <el-select v-model="form.organizationId" placeholder="Organisation" filterable remote
            :remote-method="getOrganizations" :loading="loading" clearable>
            <el-option v-for="item in orgOptions" :key="item.id" :label="item.name" :value="item.id"
              clearable></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Attribute " :label-width="formLabelWidth">
          <el-row :gutter="10" v-for="(attribute, index) in attributes" :key="index">
            <el-col :span="6">
              <el-select v-model="attribute.key" placeholder="Attributs" style="width: 100%">
                <el-option v-for="(item, index) in attributeOptions" :key="index" :label="item.label" :value="item.value"
                  clearable></el-option>
              </el-select>
            </el-col>
            <el-col :span="15">
              <el-input v-model="attribute.value" placeholder="Please input" />
            </el-col>
            <el-col :span="3">
              <el-button type="danger" plain :icon="DeleteIcon" @click="deleteAttribute(index)"></el-button>
            </el-col>
          </el-row>
        </el-form-item>
        <el-row>
          <el-col :span="4"></el-col>
          <el-col :span="20">
            <el-button type="primary" plain :icon="PlusIcon" @click="addAttribute"></el-button>
          </el-col>
        </el-row>
      </el-form>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogFormAdd = false" type="danger" plain>Annuler</el-button>
          <el-button type="primary" @click="ajoutboitier()">Ajouter</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  data() {
    return {
      orgOptions: [],
      loading: false,
      simswithnoendp: [],
      retailerOptions: [],
      attributes: [],
      attributeOptions: [
        { value: 'DOUT1' },
        { value: 'DOUT2' },
        { value: 'DOUT3' },
        { value: 'useOdometre' },
      ],
      dialogFormAdd: false,
      dialogFormSearch: false,
      formLabelWidth: '120px',
      form: {
        uniqueId: '',
        phone: '',
        make: '',
        model: '',
        organizationId: null,
        retailerId: null,
      },
      rules: {
        uniqueId: [
          {
            required: true,
            message: 'Veuillez saisir le numéro de série',
            trigger: 'change',
          },
        ],
        phone: [
          {
            required: true,
            message: 'Veuiller saisir le numéro d\'appel',
            trigger: 'change',
          },
        ],
        make: [
          {
            required: true,
            message: 'Veuillez séléctionner la marque',
            trigger: 'change',
          },
        ],
        model: [
          {
            required: false,
            message: 'Veuillez saisir le modèle',
            trigger: 'change',
          },
        ],
      },
    }
  },
  computed: {
    ...mapGetters({
      devices: 'devices/devices',
      sims: 'emnify/sims',
      endpoints: 'emnify/endpoints',
      isAppAdmin: 'auth/isAppAdmin',
      retailers: 'retailers/retailers',
      organisations: 'organisations/organisations',
      endpointsnotused: 'emnify/endpointsnotused',
    }),
  },
  mounted() {
    this.getRetailers()
  },
  methods: {
    ...mapActions({
      adddevice: 'devices/addDeviceApi',
      getOrganisations: 'organisations/getOrganisations',
    }),
    ajoutboitier() {
      let payload = {
        data: {
          uniqueId: this.form.uniqueId,
          make: this.form.make,
          model: this.form.model,
        },
      }
      if (this.form.organizationId)
        payload.data.organizationId = this.form.organizationId
      if (this.form.retailerId) payload.data.retailerId = this.form.retailerId

      if (this.attributes.length)
        payload.data.attributes = this.mappedAttributes(this.attributes)
      this.$store.dispatch('devices/addDeviceApi', payload).then(() => {
        this.form.uniqueId = ''
        this.form.phone = ''
        this.form.make = ''
        this.form.model = ''
        this.form.organizationId = null
        this.form.retailerId = null
        this.dialogFormAdd = false
      })
    },
    mappedAttributes(attributes) {
      let result = {}
      attributes.forEach((obj) => {
        result[obj.key] = obj.value
      })
      return result
    },
    addAttribute() {
      this.attributes.push({
        key: 'DOUT1',
        value: '',
      })
    },
    deleteAttribute(index) {
      this.attributes.splice(index, 1)
    },
    getOrganizations(query) {
      if (query) {
        this.loading = true
        let payload = {
          params: {
            q: query,
          },
        }
        this.$store
          .dispatch('organisations/getOrganisations', payload)
          .then((data) => {
            this.loading = false
            this.orgOptions = data
          })
      } else {
        this.orgOptions = []
      }
    },
    getRetailers() {
      if (!this.isAppAdmin) return
      this.loading = true
      if (this.retailers.length > 0) {
        this.retailerOptions = this.retailers
        this.loading = false
        return
      }
      this.$store
        .dispatch('retailers/getRetailers', {})
        .then(() => {
          this.retailerOptions = this.retailers
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>
