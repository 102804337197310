<template>
  <el-container direction="vertical">
    <el-row justify="space-between" align="middle" style="margin-top: 20px">
      <el-col :span="5">
        <div class="boitiers-title">
          <span>Boitiers</span>
          <span class="boitiers-count" v-if="totalElements > 0">{{ totalElements }}</span>
        </div>
        <el-button v-if="devicesFilter" type="info" plain class="ml-2" @click="handleFilter">
          <el-icon>
            <List />
          </el-icon>
        </el-button>


      </el-col>
      <el-col :span="2">
        <el-space alignment="flex-end">
          <AddDevice />
        </el-space>
      </el-col>
    </el-row>
    <el-table v-loading="loading" :data="devices" style="width: 100%; z-index: 1">
      <el-table-column label="N° de série" prop="uniqueId"> </el-table-column>

      <el-table-column label="Nom" prop="name"></el-table-column>
      <el-table-column label="Marque" prop="make"> </el-table-column>
      <el-table-column label="Modèle" prop="model"> </el-table-column>
      <el-table-column label="Connexion" prop="status">
        <template #default="scope">
          <el-tag size="small" :type="setStatus(scope.row.status)">{{ scope.row.status }}
          </el-tag>
        </template>
      </el-table-column>

      <el-table-column label="Etat" prop="motionState">
        <template #default="scope">{{ scope.row.motionState }} </template>
      </el-table-column>
      <el-table-column v-if="isAppUser" label="Date" prop="lastUpdate">
        <template #default="scope">{{ lastUpdate(scope.row.lastUpdate) }}
        </template>
      </el-table-column>
      <el-table-column align="right" label="Action" width="132">
        <template #default="scope">
          <el-space>
            <el-tooltip class="item" effect="dark" content="Détails" placement="left">
              <el-button size="small" :icon="MoreField" @click="handleSetting(scope.row)"></el-button>
            </el-tooltip>

            <edit-device :row="scope.row"></edit-device>
            <el-popconfirm confirmButtonText="Oui" cancelButtonText="Annuler"
              @confirm="handleDelete(scope.$index, scope.row)" :icon="InfoIcon" iconColor="#F56C6C"
              title="Voulez vous vraiment supprimer ce boitier ?">
              <template #reference>
                <el-button size="small" type="danger" :icon="DeleteIcon"></el-button>
              </template>
            </el-popconfirm>
          </el-space>
        </template>
      </el-table-column>
    </el-table>
    <el-row type="flex" class="row-bg" justify="center" v-if="metadata">
      <el-pagination :current-page="page" layout="total, prev, pager, next, jumper" :total="metadata.totalElements"
        :page-size="20" @current-change="setPage">
      </el-pagination>
    </el-row>
  </el-container>
</template>

<script>
import { List } from '@element-plus/icons-vue'
import EditDevice from './EditDevice.vue'
import AddDevice from './AddDevice.vue'
import { mapGetters, mapActions, mapState } from 'vuex'
import _ from 'lodash'
import dayjs from 'dayjs'
import { mapFields } from 'vuex-map-fields'
export default {
  components: { EditDevice, AddDevice, List },
  data() {
    return {
      res: [],
      searchorg: '',
      search: '',
      dayjs: dayjs,


    }
  },
  computed: {
    ...mapGetters({
      token: 'auth/token',
      devices: 'devices/devices',
      eminToken: 'emnify/authToken',
      metadata: 'devices/metadata',
      member: 'auth/member',
      isAppUser: 'auth/isAppUser',
    }),
    ...mapState({
      loading: 'devices/loading',
    }),
    ...mapFields('devices', ['filter']),
    ...mapFields('devices', ['devicesFilter']),

    page() {
      return Number(_.get(this.metadata, 'pageable.pageNumber', 0)) + 1
    },
    totalElements() {
      return _.get(this.metadata, 'totalElements', '0')
    },
  },
  methods: {
    ...mapActions({
      setDevice: 'devices/setDevice',
      deleteDevice: 'devices/deleteDevice',
      getDevices: 'devices/getDevice',
      getEndpointByID: 'emnify/getEndpointByID',
    }),
    formatRetailer(retailer) {
      if (retailer) return retailer.name
    },
    lastUpdate(value) {
      return value ? dayjs(value).format('YYYY-MM-DD HH:mm') : '--'
    },
    gotoDetails(index, row) {
      this.setDevice(row)
      var data = {
        endpoint_id: row.call_number,
        auth_token: this.eminToken,
      }
      this.getEndpointByID(data)
      this.$router.push('/boitierDetails')
    },
    handleEdit(index, row) {
      this.setDevice(row)
    },
    handleSetting(row) {
      this.$store.commit('devices/SET_DEVICE', row)
      this.$router.push({
        name: 'device-settings',
        params: {
          id: row.id
        },
      })
    },
    handleDelete(index, row) {
      let payload = {
        id: row.id,
        data: {
          id: row.id,
        },
      }
      this.$store.dispatch('devices/deleteDevice', payload)
    },
    filterMarque(value) {
      this.filter.make = value
      this.handleFilter()
    },
    filterModel(value) {
      this.filter.model = value
      this.handleFilter()
    },
    filterConnexion(value, row) {
      return row.status === value
    },
    filterEtat(value, row) {
      return row.motionState === value
    },
    handleFilter() {
      this.filter = {
        name: null,
        organizationId: null,
        retailerId: null,
        q: null,
        make: null,
        model: null,
        status: null,
        motionState: null,
        page: 0,
      }
      let payload = {
        params: {},
      }
      this.devicesFilter = false
      this.$store.dispatch('devices/getDevice', payload)
    },
    setPage(val) {
      this.filter.page = val - 1
      let payload = {
        params: {
          ...this.filter,
        },
      }

      this.getDevices(payload)
    },


    setStatus(status) {
      switch (status) {
        case 'online':
          return 'success'
        case 'offline':
          return 'danger'
        default:
          return 'info'
      }
    },
  },
}
</script>

<style>
.button-yoda:hover {
  background-color: #ffef9f;
  border: #ff9b2e;
  color: #ff9b2e;
}

.button-yoda-filter:hover {
  background-color: #c19ee0;
  border: white;
  color: white;
}

form label {
  font-weight: bold;
}

.boitiers-title {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
}

.boitiers-title span:first-child {
  margin-right: 5px;
}

.boitiers-count {
  font-size: 16px;
  font-weight: normal;
  margin-left: 5px;
}
</style>
